'use client';

import { Settings } from '@/models/settings';
import { observer } from 'mobx-react-lite';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';

export const PHProvider = observer(
  ({ children }: { children: React.ReactNode }) => {
    useEffect(() => {
      const initPosthog = async () => {
        const settings = (await Settings.load()).rawData;

        if (settings.posthog) {
          posthog.init(settings.posthog.apiKey, {
            api_host: settings.posthog.apiHost,
            person_profiles: 'identified_only',
            capture_pageleave: true,
            enable_recording_console_log: true,
          });
        }
      };

      initPosthog();
    }, []);
    return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
  },
);
