import(/* webpackMode: "eager" */ "/home/nalbion/nullify/Rebel-Alliance/galactic-empire/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/nalbion/nullify/Rebel-Alliance/galactic-empire/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/nalbion/nullify/Rebel-Alliance/galactic-empire/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"DM_Sans\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"DMSans\"}");
;
import(/* webpackMode: "eager" */ "/home/nalbion/nullify/Rebel-Alliance/galactic-empire/src/app/_components/posthog-page-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SiteHeader"] */ "/home/nalbion/nullify/Rebel-Alliance/galactic-empire/src/app/_components/site-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/home/nalbion/nullify/Rebel-Alliance/galactic-empire/src/app/_providers/posthog-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RootStoreProvider"] */ "/home/nalbion/nullify/Rebel-Alliance/galactic-empire/src/app/_providers/root-store-provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/nalbion/nullify/Rebel-Alliance/galactic-empire/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/nalbion/nullify/Rebel-Alliance/galactic-empire/src/components/ui/toaster.tsx");
